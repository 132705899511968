import { CategoryValue } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Checkbox } from '@mui/material';
import React from 'react';

import { IconPicker } from './IconPicker.component';
import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';

const DEFAULT_ICON = '💸';

export const AddNewCategory: React.FC<{ onAddNewCategory: () => void; onDiscard: () => void }> = ({
  onAddNewCategory,
  onDiscard,
}) => {
  const [categoryValue, setCategoryValue] = React.useState<Omit<CategoryValue, 'color'>>({
    icon: DEFAULT_ICON,
    name: '',
    deductible: false,
  });
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const cachedCategories = Data.expenseCategories.use();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (categoryValue.name) {
      setIsDirty(true);
    }
    const doesSameNameCategoryExist = cachedCategories.some(
      (category) => category.value.name === categoryValue.name,
    );
    setErrorMessage('');
    if (doesSameNameCategoryExist) {
      setErrorMessage('A category with the same name already exists');
    }
  }, [categoryValue.name]);

  function onSave() {
    Data.expenseCategories.create({ value: categoryValue! });
    onAddNewCategory();
  }

  return (
    <Layout.Column>
      <Layout.Row>
        <Layout.Column align>
          <OpenSans.Custom
            size="xs-12"
            color="secondary"
            weight="bold-700"
            style={{ textAlign: 'center' }}
          >
            Icon
          </OpenSans.Custom>
          <Spacer.Vertical size={6} />
          <Layout.Column>
            <Layout.Column
              radius={4}
              px
              py={4}
              style={{
                minHeight: 40,
              }}
              bg="navHeaderBackground"
              justify
            >
              <IconPicker
                icon={categoryValue?.icon ?? DEFAULT_ICON}
                onChange={(icon) => setCategoryValue({ ...categoryValue, icon })}
              />
            </Layout.Column>
          </Layout.Column>
        </Layout.Column>

        <Spacer.Horizontal size={32} />
        <LabelTextField
          label="Name"
          active={false}
          styles={{ backgroundColor: theme.colors.inputBackground }}
          error={!categoryValue.name && isDirty}
          errorMessage={errorMessage}
        >
          <OpenSans.Input
            name="category name"
            value={categoryValue?.name ?? ''}
            type="text"
            placeholder="Category Name"
            grow
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const name = event.target.value;
              setCategoryValue({ ...categoryValue, name });
            }}
            autoComplete="off"
          />
        </LabelTextField>

        <Spacer.Horizontal size={32} />
        <LabelTextField label="Tax Deductible" styles={{ border: 'none' }}>
          <Layout.Row px>
            <Checkbox
              style={{ padding: 0 }}
              checked={categoryValue?.deductible ?? undefined}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const deductible = event.target.checked;
                setCategoryValue({ ...categoryValue, deductible });
              }}
            />
          </Layout.Row>
        </LabelTextField>
      </Layout.Row>

      <Spacer.Vertical size={32} />
      <Layout.Row>
        <Button.Secondary
          onClick={onDiscard}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 4px rgba(255, 255, 255, 1)',
          }}
        >
          <Icon
            size={15}
            color={theme.colors.brandPrimary}
            style={{ paddingRight: 10 }}
            name="chevron-back"
          />
          <OpenSans.Primary
            size={15}
            weight="bold-700"
            style={{ color: theme.colors.brandPrimary }}
          >
            Discard
          </OpenSans.Primary>
        </Button.Secondary>
        <Spacer.Horizontal size={32} />
        <Button.Primary
          onClick={onSave}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
            width: 'auto',
          }}
          disabled={!categoryValue?.name || errorMessage}
        >
          <OpenSans.Custom size={15} weight="bold-700" style={{ color: theme.colors.buttonWhite }}>
            Add Category
          </OpenSans.Custom>
          <Icon
            size={15}
            color={theme.colors.buttonWhite}
            style={{ paddingLeft: 10 }}
            name="chevron-forward"
          />
        </Button.Primary>
      </Layout.Row>
    </Layout.Column>
  );
};
