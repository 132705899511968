import { DEFAULT_SORT_ORDER, useMultiple } from '@easy-expense/data-firestore-client';
import { Expense, ReportWithData } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { ExpenseRow } from './ExpenseRow.component';
import { ExpenseSearch } from './ExpenseSearch.component';
import { applyFilters, useMappedExpenseData, useSearchExpenseList } from './helpers';

interface ExpenseListProps {
  onRowClick: (expense: Expense) => void;
  filter?: (e: Expense) => boolean;
  showBulk?: boolean;
  header?: React.ReactNode;
  filterUser?: string;
  selected?: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
  isReport?: boolean;
  showSearch?: boolean;
  report?: ReportWithData;
}

export const ExpenseList: React.FC<React.PropsWithChildren<ExpenseListProps>> = ({
  onRowClick,
  filter = () => true,
  filterUser,
  header,
  showBulk = false,
  selected = [],
  setSelected = () => {},
  isReport = false,
  showSearch = true,
  report,
}) => {
  const [search, setSearch] = React.useState<string>();

  const expensesWithData = useMappedExpenseData();
  const filteredExpenses = applyFilters(expensesWithData, filter, filterUser);
  const queriedExpenses = useSearchExpenseList(filteredExpenses, search);
  const expenses = queriedExpenses.sort((a, b) => (a.date < b.date ? 1 : -1));

  return (
    <Layout.Column py style={{ width: '100%' }}>
      <Layout.Column>
        {showSearch ? <ExpenseSearch search={search} setSearch={setSearch} /> : null}
        {header}
      </Layout.Column>
      <Layout.Table>
        <Layout.TableHeader>
          <Layout.TableHeaderRow py>
            {showBulk ? (
              <Layout.TableHeaderCell>
                <Layout.Row>
                  <Spacer.Horizontal size={32} />
                </Layout.Row>
              </Layout.TableHeaderCell>
            ) : null}

            <Layout.TableHeaderCell>
              <Layout.Row>
                <OpenSans.Secondary size={'s-16'}>{getTranslation('Date')}</OpenSans.Secondary>
                <Spacer.Horizontal size={32} />
              </Layout.Row>
            </Layout.TableHeaderCell>
            <Layout.TableHeaderCell>
              <Layout.Row>
                <OpenSans.Secondary size={'s-16'}>{getTranslation('To/From')}</OpenSans.Secondary>
                <Spacer.Horizontal size={32} />
              </Layout.Row>
            </Layout.TableHeaderCell>
            <Layout.TableHeaderCell>
              <Layout.Row>
                <OpenSans.Secondary size={'s-16'}>{getTranslation('Category')}</OpenSans.Secondary>
                <Spacer.Horizontal size={32} />
              </Layout.Row>
            </Layout.TableHeaderCell>

            {!isReport ? (
              <>
                <Layout.TableHeaderCell>
                  <Layout.Row>
                    <OpenSans.Secondary size={'s-16'}>
                      {getTranslation('Created By')}
                    </OpenSans.Secondary>
                    <Spacer.Horizontal size={32} />
                  </Layout.Row>
                </Layout.TableHeaderCell>
                <Layout.TableHeaderCell>
                  <Layout.Row>
                    <OpenSans.Secondary size={'s-16'}>
                      {getTranslation('Account')}
                    </OpenSans.Secondary>
                    <Spacer.Horizontal size={32} />
                  </Layout.Row>
                </Layout.TableHeaderCell>
                <Layout.TableHeaderCell>
                  <Layout.Row>
                    <OpenSans.Secondary size={'s-16'}>
                      {getTranslation('Report')}
                    </OpenSans.Secondary>
                    <Spacer.Horizontal size={32} />
                  </Layout.Row>
                </Layout.TableHeaderCell>
              </>
            ) : null}

            <Layout.TableHeaderCell>
              <Layout.Row justify="flex-end">
                <OpenSans.Secondary size={'s-16'} px style={{ textAlign: 'right' }}>
                  {getTranslation('Amount')}
                </OpenSans.Secondary>
              </Layout.Row>
            </Layout.TableHeaderCell>
            <Layout.TableHeaderCell>
              <Layout.Row justify="flex-end">
                <OpenSans.Secondary size={'s-16'} px>
                  {getTranslation('Receipt')}
                </OpenSans.Secondary>
              </Layout.Row>
            </Layout.TableHeaderCell>
          </Layout.TableHeaderRow>
        </Layout.TableHeader>
        <Layout.TableBody>
          {expenses.map((expense) => (
            <ExpenseRow
              key={expense.key}
              expense={expense}
              selected={selected.includes(expense.key)}
              showBulk={showBulk}
              isReport={isReport}
              onRowClick={onRowClick}
              report={report}
            />
          ))}
        </Layout.TableBody>
      </Layout.Table>
    </Layout.Column>
  );
};
