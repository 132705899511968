import { Category } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { IconName } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { ExportFilters } from './types';
import { AutoCompleteValue } from '../Shared/Dropdown/AutoCompleteTypes';
import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

export const usePaymentMethodsAutoComplete = (): AutoCompleteValue[] => {
  const paymentMethods = Data.paymentMethods.use();

  return React.useMemo(() => {
    return [
      ...paymentMethods.map((method) => {
        return {
          key: method.key,
          value: {
            name: method.value.name,
            image: null,
            iconicIcon: 'card-outline' as IconName,
          },
        };
      }),
    ];
  }, [paymentMethods]);
};

export const ExportExpenseFilters: React.FC<{
  exportFilters: ExportFilters;
  setExportFilters: (filters: ExportFilters) => void;
}> = ({ exportFilters, setExportFilters }) => {
  const allVendors = Data.vendors.use();
  const categories: Category[] = Data.expenseCategories.use();
  const paymentMethods = usePaymentMethodsAutoComplete();

  return (
    <Layout.Column>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Expense Filters')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <SearchDropdown
          header={getTranslation('Vendors')}
          placeholder={getTranslation('All Vendors')}
          selectedKey={exportFilters.vendorKey}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Vendors', iconicIcon: 'storefront-outline' } },
            ...allVendors,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...exportFilters, vendorKey: c?.key });
          }}
          error={false}
          iconicIcon="storefront-outline"
        />
        <Spacer.Horizontal />

        <SearchDropdown
          header={getTranslation('Category')}
          placeholder={getTranslation('All Categories')}
          selectedKey={exportFilters.categoryKey}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Categories', iconicIcon: 'pricetag-outline' } },
            ...categories,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...exportFilters, categoryKey: c?.key });
          }}
          error={false}
          iconicIcon="pricetag-outline"
        />
        <Spacer.Horizontal />
        <SearchDropdown
          header={getTranslation('Payment Methods')}
          placeholder={getTranslation('All Payment Methods')}
          selectedKey={exportFilters.paymentMethodKey}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Payment Methods', iconicIcon: 'card-outline' } },
            ...paymentMethods,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...exportFilters, paymentMethodKey: c?.key });
          }}
          error={false}
          iconicIcon="card-outline"
        />
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
