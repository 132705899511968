import { Expense } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { ScannerResultExpense, useScanReceipt } from './scanner';
import { FirestorageFileUrl, uploadReceipts } from './uploader';

export const ReceiptViewer: React.FC<
  React.PropsWithChildren<{
    receipts: string[];
    onChange?: (receipts: string[]) => void;
    selectedReceiptIndex: number;
    setSelectedReceiptIndex: (receiptIndex: number) => void;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isNewExpense?: boolean;
    setExpense?: React.Dispatch<React.SetStateAction<Partial<Expense>>>;
  }>
> = ({
  receipts,
  onChange,
  selectedReceiptIndex,
  setSelectedReceiptIndex,
  setLoading,
  isNewExpense = false,
  setExpense,
}) => {
  const scanReceipt = useScanReceipt();
  const categories = Data.expenseCategories.use();

  function updateReceipts(imageUrls: FirestorageFileUrl[]) {
    const allReceipts: string[] = [...receipts, ...imageUrls];
    setSelectedReceiptIndex(allReceipts.length - 1);
    if (onChange) {
      onChange(allReceipts);
    }
  }

  function showNextReceipt() {
    const newIndex = selectedReceiptIndex + 1;
    if (newIndex === receipts.length) {
      setSelectedReceiptIndex(0);
    } else {
      setSelectedReceiptIndex(newIndex);
    }
  }

  function showPreviousReceipt() {
    const newIndex = selectedReceiptIndex - 1;
    if (newIndex < 0) {
      setSelectedReceiptIndex(receipts.length - 1);
    } else {
      setSelectedReceiptIndex(newIndex);
    }
  }

  const uncategorizedCategory = categories.find((c) => c.value.name === 'Uncategorized');

  async function onUpload(files?: FileList | null) {
    setLoading(true);
    if (files && files.length === 1 && isNewExpense) {
      let scannedExpense: ScannerResultExpense | undefined;
      try {
        scannedExpense = await scanReceipt(files[0]);
      } catch (err) {
        console.warn('Failed to scan receipt: ', err);
      }
      const uploadedReceipts = await uploadReceipts(files);
      setSelectedReceiptIndex(0);

      let vendorKey: string | undefined;
      if (scannedExpense?.vendor) {
        vendorKey = Data.vendors.create({ value: { name: scannedExpense.vendor } });
      }

      if (setExpense) {
        setExpense({
          receipts: uploadedReceipts,
          total: scannedExpense?.total,
          tax: scannedExpense?.tax,
          category: scannedExpense?.categoryKey ?? uncategorizedCategory?.key ?? '',
          vendor: vendorKey,
          paymentMethod: scannedExpense?.paymentMethodKey,
          date: scannedExpense?.date,
        });
      }
    } else {
      const uploadedReceipts = await uploadReceipts(files);
      updateReceipts(uploadedReceipts);
    }
    setLoading(false);
  }

  return (
    <Layout.Column
      style={{ height: '100%', backgroundColor: '#CFD6E0' }}
      onDrop={(event: DragEvent) => {
        event.preventDefault();
        onUpload(event?.dataTransfer?.files);
      }}
      onDragOver={(event: DragEvent) => event.preventDefault()}
      justify
    >
      {!receipts.length ? (
        <Layout.Row center>
          <label
            htmlFor="files"
            style={{
              borderStyle: 'dashed',
              borderWidth: 1,
              borderRadius: 6,
              borderColor: theme.colors.brandPrimary,
              backgroundColor: theme.colors.brandPrimaryXLight,
              margin: 32,
              padding: 32,
              display: 'flex',
            }}
          >
            <Layout.Column center style={{ justifyContent: 'space-between' }}>
              <Icon name="receipt-outline" size={23} color={theme.colors.brandPrimary} />
              <Spacer.Vertical size={12} />
              <OpenSans.Pressable weight="bold-700">Drag & drop receipts</OpenSans.Pressable>
              <Spacer.Vertical size={4} />
              <OpenSans.Pressable size="xs-12">
                We will scan details automatically
              </OpenSans.Pressable>
              <input
                type="file"
                id="files"
                multiple
                accept=".png,.jpg,.jpeg,.pdf"
                onChange={(event) => onUpload(event.target.files)}
                style={{ display: 'none' }}
              />
            </Layout.Column>
          </label>
        </Layout.Row>
      ) : (
        <Layout.Column style={{ height: '100%', width: '100%' }} center>
          <img
            src={receipts[selectedReceiptIndex]}
            style={{
              height: '90vh',
              width: '90%',
              objectFit: 'contain',
            }}
          />

          <Layout.Row
            py
            style={{
              position: 'absolute',
              bottom: 32,
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
            }}
            bg="brandPrimaryDark"
            radius
            align
          >
            <Layout.PressableColumn style={{ background: 'none' }} px onClick={showPreviousReceipt}>
              <Icon name="chevron-back" size={16} color={'white'} />
            </Layout.PressableColumn>
            <OpenSans.Inverse size="xs-12">
              {selectedReceiptIndex + 1} / {receipts.length}
            </OpenSans.Inverse>
            <Layout.PressableColumn style={{ background: 'none' }} px onClick={showNextReceipt}>
              <Icon name="chevron-forward" size={16} color={'white'} />
            </Layout.PressableColumn>
          </Layout.Row>
        </Layout.Column>
      )}
    </Layout.Column>
  );
};
