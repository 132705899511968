import { currentUser } from '@easy-expense/auth-client';
import { useCurrentOrganization } from '@easy-expense/data-firestore-client';
import { Category, PaymentMethod, Vendor } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import {
  WorkspaceType,
  getVendorCategoryFromUserGenerator,
  titleCase,
} from '@easy-expense/utils-shared';
import { getDateFromReceiptText } from '@easy-expense/utils-shared/src/AutoLabel/dateFromReceipt';
import { fuzzyArrayContains } from '@easy-expense/utils-shared/src/AutoLabel/fuzzyContains';
import {
  categoryPredictions,
  vendorPredictions,
} from '@easy-expense/utils-shared/src/AutoLabel/naiveBayes';
import { format } from 'date-fns';
import React from 'react';
import { OEM, createWorker } from 'tesseract.js';

import { extractTextFromPDF } from './pdf';
import { isFileImage, isFilePdf } from './uploader';

export function useScanReceipt() {
  const paymentMethods = Data.paymentMethods.use();
  const vendors = Data.vendors.use();
  const categories = Data.expenseCategories.use();
  const currentOrganization = useCurrentOrganization();
  const workspaceType = currentOrganization?.organizationType ?? 'business';

  return React.useCallback(
    async (receipt: File): Promise<ScannerResultExpense | undefined> => {
      console.log('Scanning: ', receipt);
      let receiptText = '';
      if (isFilePdf(receipt)) {
        receiptText = await extractTextFromPDF(receipt);
      }

      if (isFileImage(receipt)) {
        receiptText = await scanTextFromImage(receipt);
      }

      const workspaceProps = {
        paymentMethods,
        vendors,
        categories,
        workspaceType,
      };
      const expense = await extractExpense(receiptText, workspaceProps);
      return expense;
    },
    [vendors, categories, workspaceType],
  );
}

export async function scanTextFromImage(imageFile: File) {
  const workerPath = new URL('tesseract.js/dist/worker.min.js', import.meta.url).toString();

  const worker = await createWorker('eng', OEM.DEFAULT, {
    workerBlobURL: false,
    workerPath,
  });
  const ret = await worker.recognize(imageFile);
  await worker.terminate();
  console.log('OCR: ', ret.data.text);
  return ret.data.text;
}

async function extractExpense(
  receiptText: string,
  workspace: WorkspaceProps,
): Promise<ScannerResultExpense> {
  const noAccentsText = receiptText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const date = format(getDateFromReceiptText({ receiptText: noAccentsText }), 'yyyy-MM-dd');
  const paymentMethodKey = getPaymentMethodFromReceiptText({
    receiptText,
    paymentMethods: workspace.paymentMethods,
  });
  const { total } = getTotalFromReceiptText(noAccentsText);
  const { tax } = getTaxFromReceiptText({
    sortedText: noAccentsText,
    total,
  });
  const { categoryKey, vendor } = getVendorAndCategoryFromReceiptText({
    receiptText: noAccentsText,
    vendors: workspace.vendors,
    categories: workspace.categories,
    workspaceType: workspace.workspaceType,
  });

  return {
    paymentMethodKey,
    date,
    tax,
    total,
    categoryKey,
    vendor,
    rawText: noAccentsText,
  };
}

type WorkspaceProps = {
  vendors: Vendor[];
  categories: Category[];
  paymentMethods: PaymentMethod[];
  workspaceType: WorkspaceType;
};

export type ScannerResultExpense = {
  date: string;
  tax: number;
  total: number;
  categoryKey: string;
  vendor: string;
  paymentMethodKey: string;
  rawText: string;
};

export type ScannerResultSuggestions = {
  taxes: number[];
  totals: number[];
  categoryKeys: string[];
  vendors: string[];
};

export const getVendorAndCategoryFromReceiptText = ({
  receiptText,
  vendors,
  categories,
  workspaceType,
}: {
  receiptText: string;
  vendors: Vendor[];
  categories: Category[];
  workspaceType: WorkspaceType;
}) => {
  const receiptArray = receiptText.toLocaleLowerCase().split('\n');

  const vendorSuggestions: string[] = [];
  const categorySuggestions: string[] = [];

  const { vendor: userVendor, category: categoryFromUserVendor } =
    getVendorCategoryFromUserGenerator(fuzzyArrayContains)(
      receiptArray,
      vendors,
      categories,
      workspaceType,
    );
  if (userVendor) {
    vendorSuggestions.push(userVendor.value.name);
    if (categoryFromUserVendor) {
      categorySuggestions.push(categoryFromUserVendor.value.name);
    }
  }

  const naiveBayesVendors = vendorPredictions(receiptArray);
  const naiveBayesVendorFromReceipt = fuzzyArrayContains(
    receiptArray,
    naiveBayesVendors.map((v) => v.toLowerCase()),
  );
  if (naiveBayesVendorFromReceipt !== undefined) {
    vendorSuggestions.push(titleCase(naiveBayesVendorFromReceipt));
  }

  const firstLineVendorCandidate = titleCase(
    receiptArray.find((line) => {
      return line.length > 4;
    }) ?? '',
  );

  if (isUnlikelyToBeVendorName(firstLineVendorCandidate)) {
    if (vendorSuggestions.length === 0) {
      vendorSuggestions.push('Unknown Vendor');
    }
  } else {
    vendorSuggestions.push(firstLineVendorCandidate);
  }

  vendorSuggestions.push(...naiveBayesVendors);

  const naiveBayesCategories = categoryPredictions({ receiptArray, workspaceType });
  categorySuggestions.push(...naiveBayesCategories);

  const categoryKeys = categorySuggestions
    .map((c) => categories.find((cat) => cat.value.name === c)?.key)
    .filter((c) => c) as string[];

  const vendorSet = [...new Set(vendorSuggestions)];
  const categorySet = [...new Set(categoryKeys)];

  return {
    vendor: vendorSet[0] ?? '',
    categoryKey: categorySet[0] ?? '',
    vendorSuggestions: vendorSet.slice(1),
    categoryKeySuggestions: categorySet.slice(1),
  };
};

export const getPaymentMethodFromReceiptText = ({
  receiptText,
  paymentMethods,
}: {
  receiptText: string;
  paymentMethods: PaymentMethod[];
}) => {
  const paymentMethod = paymentMethods.find((pm) => {
    return pm.value.digits.length && receiptText.includes(pm.value.digits);
  });
  return (
    paymentMethod?.key ??
    paymentMethods.find((pm) => pm.value.isDefault && pm.createdBy === currentUser()?.uid)?.key ??
    ''
  );
};

export const getTotalFromReceiptText = (sortedText: string) => {
  const smartTotals: number[] = [];
  [
    ...sortedText.matchAll(
      new RegExp(
        `(?:(?:sub|sub.)?total|due|tend|sale|usd|cad|aud|credit|purchase|primary)\\D*${dollarAmountRegex}`,
        'gi',
      ),
    ),
  ].forEach(([match, num]) => {
    if (!/sub/i.exec(match ?? '')) {
      const parsed = extractFloat(num as string);
      if (parsed < 10000) {
        smartTotals.push(parsed);
      }
    }
  });
  const dumbTotals: number[] = [];
  [...sortedText.matchAll(new RegExp(dollarAmountRegex, 'gi'))].forEach(([, num]) => {
    const parsed = extractFloat(num as string);
    if (parsed < 10000) {
      dumbTotals.push(parsed);
    }
  });

  const suggestions = [
    ...new Set([...smartTotals.sort((a, b) => b - a), ...dumbTotals.sort((a, b) => b - a)]),
  ];

  return { total: suggestions[0] ?? 0, suggestions: suggestions.slice(1) };
};

export const getTaxFromReceiptText = ({
  sortedText,
  total,
}: {
  sortedText: string;
  total: number;
}) => {
  const subTotalTaxValues: number[] = [];

  const cleanedText = sortedText
    .split('\n')
    .map((line) => line.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''));

  cleanedText.forEach((line) => {
    if (line.replaceAll('0', 'o').includes('subtotal')) {
      const subtotal = parseFloat(line.replaceAll('o', '0').replace(/[^0-9]/g, '')) / 100;
      if (subtotal < total && subtotal > 0.8 * total) {
        subTotalTaxValues.push(total - subtotal);
      }
    }
  });

  // TODO implement search with cleaned text as seen above for more resilient tax extraction
  const directTaxValues = [
    ...sortedText.matchAll(/\Wgst[^0-9]*(\d+[.][ ]?\d{2})(?![ ]*%)/gi),
    ...sortedText.matchAll(/\Wvat[^0-9]*(\d+[.][ ]?\d{2})(?![ ]*%)/gi),
    ...sortedText.matchAll(/\Wtax[^0-9]*(\d+[.][ ]?\d{2})(?![ ]*%)/gi),
    ...sortedText.matchAll(/\Wpst[^0-9]*(\d+[.][ ]?\d{2})(?![ ]*%)/gi),
  ]
    .map((tr) => extractFloat(tr[1] ?? '0'))
    .filter((t) => t < 0.2 * total)
    .sort((a, b) => b - a);

  const taxSuggestions = [
    ...new Set([...subTotalTaxValues.sort((a, b) => a - b), ...directTaxValues]),
  ];

  return { tax: taxSuggestions[0] ?? 0, suggestions: taxSuggestions.slice(1) };
};

const dollarAmountRegex = '(\\d{1,3}(,?\\d{3})*[,.][ ]?\\d{2}\\b)(?![ ]*%)';

function extractFloat(numberString: string) {
  return parseFloat(
    numberString
      .replace(/ /g, '') // remove spaces
      .replace(/,/g, '.') // replace commas with dots
      .replace(/\.(?=[^.]*\.)/g, ''), // remove all but the last dot
  );
}

const isUnlikelyToBeVendorName = (s: string) => {
  return s.length <= 4 || s.replace(/[a-z ]/gi, '').length > 4;
};
