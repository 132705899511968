import Bugsnag from '@bugsnag/js';
import { getSendInviteVerifyEmailFn, getVerifyEmailFn } from '@easy-expense/auth-client';
import { useOwnWorkspaceMemberships } from '@easy-expense/data-firestore-client';
import { fuego, reloadUserToken } from '@easy-expense/firebase-app';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';
import { Linking, Image } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../components/Button.components';
import LoadingSpinner from '../components/LoadingSpinner.component';
import {
  OnboardingActionColumn,
  OnboardingBackdrop,
} from '../components/Onboarding/OnboardingBackdrop.component';
import { SignUpInvited } from '../components/SignUpInvited.form';
import { useWindowDimensions } from '../hooks/useWindow';

const SniperLink: React.FC<React.PropsWithChildren<{ email: string }>> = ({ email }) => {
  let link = `https://mail.google.com/mail/u/${email}/#search/from%3A%40easy-expense.com+in%3Aanywhere+newer_than%3A1h`;
  let inboxName = 'Gmail';
  let imageSrc = 'gmail';
  const [, domain] = email.split('@');
  switch (domain) {
    case 'yahoo.com':
      inboxName = 'Yahoo Mail';
      imageSrc = 'yahoo';
      link = `https://mail.yahoo.com/d/search/keyword=from%253Aeasy-expense.com`;
      break;
    case 'hotmail.com':
    case 'outlook.com':
    case 'live.com':
      inboxName = 'Outlook';
      imageSrc = 'outlook';
      link = `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(email)}`;
      break;
    default:
      break;
  }
  return (
    <Button.Default
      // @ts-ignore
      style={{ width: 250 }}
      onClick={() => {
        Linking.openURL(link);
      }}
      py
      center
    >
      <Layout.Row center align>
        <Image
          source={require(`../../assets/logos/${imageSrc}.png`)}
          style={{ width: 24, height: 24 }}
        />
        <Spacer.Horizontal />
        <OpenSans.Primary weight="bold-700">Open {inboxName}</OpenSans.Primary>
        <Spacer.Horizontal />
        <Icon name="open-outline" size={24} />
      </Layout.Row>
    </Button.Default>
  );
};

export const InviteAccept: React.FC = () => {
  const { width } = useWindowDimensions();
  const small = width < 800;
  const [formState, setState] = React.useState<'none' | 'working'>('none');
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = fuego.auth().currentUser;
  const workspaces = useOwnWorkspaceMemberships();

  const workspaceName = params.get('workspaceName') ?? '';
  const workspaceLogo = params.get('workspaceLogo') ?? '';
  const inviteID = params.get('inviteID') ?? '';
  const email = params.get('email') ?? '';
  const userID = currentUser?.uid ?? '';

  React.useEffect(() => {
    if (workspaces.length && navigate) {
      navigate?.('/accepted');
    }
  }, [workspaces, navigate]);

  const checkUserVerification = (verifyToken: string) => {
    setState('working');
    getVerifyEmailFn()({ verifyToken })
      .then(async ({ data }) => {
        if (data.success) {
          await reloadUserToken();
        }
      })
      .finally(() => {
        setState('none');
      });
  };

  React.useEffect(() => {
    if (currentUser) {
      if (params.get('verifyToken')) {
        checkUserVerification(params.get('verifyToken') as string);
      } else {
        getSendInviteVerifyEmailFn()({
          workspaceName,
          workspaceLogo,
          inviteID,
          userID,
        });
      }
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (!params.get('inviteID')) {
      navigate?.('/');
    }
  }, [params, navigate]);

  function renderInviteAction() {
    if (formState === 'working' || currentUser?.emailVerified) {
      return (
        <Layout.Column center>
          <LoadingSpinner size={48} />

          <Spacer.Vertical size={24} />

          <OpenSans.Primary size="l-20" center>
            Creating Your Account...
          </OpenSans.Primary>
        </Layout.Column>
      );
    }
    if (currentUser) {
      return (
        <OpenSans.Primary weight="regular-400" size={small ? 'l-20' : '2xl-32'}>
          <Layout.Column center>
            <Spacer.Vertical size={small ? 12 : 18} />
            <OpenSans.Primary size="l-20" center>
              Verify your email to continue ({currentUser.email})
            </OpenSans.Primary>
            <Spacer.Vertical size={small ? 12 : 18} />
            <SniperLink email={currentUser.email!} />
            <Spacer.Vertical size={small ? 12 : 18} />
            <Button.Primary
              // @ts-ignore
              onClick={async () => {
                try {
                  await getSendInviteVerifyEmailFn()({
                    workspaceName,
                    workspaceLogo,
                    inviteID,
                    userID,
                  });
                  alert('Please check your inbox and spam folder for verification instructions');
                } catch (e) {
                  Bugsnag.notify(e as DoNotFixMeIAmAny);
                  alert('An error occurred, please try again');
                }
              }}
              py
              content="Resend Email"
            />
          </Layout.Column>
        </OpenSans.Primary>
      );
    }
    return (
      <Layout.Column>
        <OpenSans.Primary weight="regular-400" size={'xl-28'} center>
          <p>
            Sign up to join the
            <b>{workspaceName ? ` ${workspaceName} ` : ' '}</b>
            workspace
          </p>
        </OpenSans.Primary>
        <SignUpInvited email={email} />
      </Layout.Column>
    );
  }

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn width={430}>
        <Layout.Column align>{renderInviteAction()}</Layout.Column>
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
